import Link from "next/link";
import { useRouter } from "next/router";
import { useContext } from "react";
import { CImage, SalesforceLogin } from "../components";
import { getGlobalStaticProps } from "../lib/helpers";
import { GlobalPropsContext } from "./_app";

export default function LoginPage() {
  const { config } = useContext(GlobalPropsContext);
  const router = useRouter();
  const redirectUrl = router.query.redirect_url as string;

  return (
    <div className="container d-flex flex-column">
      <div className="row align-items-center justify-content-center no-gutters min-vh-100">
        <div className="col-lg-5 col-md-8 py-8 py-xl-0">
          <div className="card shadow text-center">
            <div className="card-body p-6">
              <div className="mb-4">
                <div className="d-inline-block">
                  <Link href="/">
                    <CImage
                      src={config.logo}
                      width={200}
                      height={30}
                      // layout="intrinsic"
                      alt={`${config.company_name} logo`}
                      objectFit="contain"
                    />
                  </Link>
                </div>
                <h3 className="mb-1 mt-5 fw-bold">Login Effortlessly</h3>
              </div>
              <SalesforceLogin redirectUrl={redirectUrl} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export async function getStaticProps() {
  const props = await getGlobalStaticProps(
    {},
    {
      pageTitle: `Login`,
      disableLayout: true,
    }
  );
  return { props };
}
